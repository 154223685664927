export const ENVIRONMENT =
    process.env.NEXT_PUBLIC_VERCEL_APP_ENV || process.env.NODE_ENV;

export const UID_COOKIE = 'viome_uid';

export const IRCLICKID_COOKIE = 'viome_irclickid';
export const MEDIA_PARTNER_ID_COOKIE = 'viome_media_id';

export const CURRENCY = 'USD';
export const CURRENCY_SHORT = 'US';

export const APP_HOST =
    ENVIRONMENT === 'preview'
        ? 'https://preview.viomelifesciences.com'
        : process.env.APP_HOST;

export const APP_NAME = 'viome';

export const VIOME_COM_URL = 'https://www.viome.com';

export const GOOGLE_SCHEMA_BRAND = 'Viome';

export const SUPPORT_URL = 'https://support.viome.com';

export const CTA_TEXT = {
    DEFAULT: 'Add to cart',
    SUBSCRIPTION: 'Get started',
};

export const COOKIE_POLICY = {
    AGE: 30 * 24 * 60 * 60, // 30 Days
    BUTTON_ACCEPT_TEXT: 'Accept',
    COPY: 'Viome, and the companies we work with, use cookies and other technologies to collect data to improve your experience on our site, analyze site usage, and facilitate advertising. By continuing to browse this site, you agree to our ',
    EU_COPY:
        'We use cookies to offer you a better user experience and analyze site traffic. By continuing to use this website, you consent to the use of cookies in accordance with our ',
    NAME: 'cookie_consent_status',
    BUTTON_REJECT_TEXT: 'Reject',
};

export const COOKIE_DOMAIN =
    ENVIRONMENT === 'production' ? 'viomelifesciences.com' : null;

export const CART_URL =
    ENVIRONMENT === 'production'
        ? 'https://buy.viome.com/cart'
        : 'https://staging-buy.viome.com/cart';

export const ADD_TO_CART_URL =
    ENVIRONMENT === 'production'
        ? 'https://buy.viome.com/pages/addtocart'
        : 'https://staging-buy.viome.com/pages/addtocart';

export const COUNTRY_COOKIE_NAME = 'viome_country_code_v2';

export const COUNTRY_IS_EU_OR_GB_COOKIE_NAME = 'viome_country_is_eu_or_gb_v2';

export const GOOGLE_ANALYTICS = {
    SCOPE: 'https://www.googleapis.com/auth/analytics.readonly',
    MARKETING_VIEW_ID: 173388273,
};

export const GOOGLE_TAG_MANAGER_CONTAINER =
    ENVIRONMENT === 'production' ? 'GTM-TKPWGR3' : 'GTM-TKPWGR3';

export const SHOPIFY = {
    STAGING_HOST: 'staging-buy',
    STAGING_ACCESS_TOKEN: '1b38719131281ebbf306d9fd75f04452',
    STAGING_DOMAIN: 'viome-integration',
    PRODUCTION_HOST: 'buy',
    PRODUCTION_ACCESS_TOKEN: '059b3e6a5bdc7c303c420122f41c9fc8',
    PRODUCTION_DOMAIN: 'viome1',
};

export const SHOPIFY_HOST_SUBDOMAIN =
    ENVIRONMENT === 'production' ? 'buy' : 'staging-buy';

export const SHOPIFY_CART_COOKIE_NAME =
    ENVIRONMENT === 'production'
        ? 'shopify-cart-items'
        : 'shopify-cart-items-staging';

export const CONTENTFUL = {
    ENVIRONMENT: 'master',
    SLUG: 'viome-life-science',
    LOCALE: 'en-US',
};

export const contentfulOptions = {
    CONTENTFUL_PREVIEW_ACCESS_TOKEN:
        process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
    CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID,
    isPreview: ENVIRONMENT === 'development' || ENVIRONMENT === 'preview',
    websiteSlug: 'viome-life-science',
};

export const KLAVIYO = {
    EMBED_FOOTER_SIGNUP_FORM_ID: 'UsRwXb',
    EMBED_BLOG_SIGNUP_FORM_ID: 'WfuKn6',
};

export const BLOG_ARTICLES_STEP_LIMIT = 24;
export const BLOG_INDEX_LIMIT = 3;
export const BLOG_INDEX_BODY_LIMIT = 6;

export const VIEWPORT_WIDTHS = {
    MOBILE: 0,
    TABLET: 768,
    LARGE: 1024,
    DESKTOP: 1200,
    XL: 1440,
};

export const VIEWPORT_LABELS = {
    MOBILE: 'Small',
    TABLET: 'Medium',
    LARGE: 'Large',
    DESKTOP: 'DESKTOP',
    XL: 'Xl',
};

export const VIEWPORT_MEDIA_QUERIES = {
    MOBILE: 'max-width: 767px',
    TABLET: 'min-width: 768px',
    DESKTOP: 'min-width: 1024px',
    XL: 'min-width: 1200px',
};

export const SCORE_BULLETS = {
    'Gut Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/3wp4l4WBOpMN2Uam91yLM7/dc3cf107ed8ea57cb871a600c9e4df14/Frame_257__2_.png',
    'Cellular & Energy Efficiency':
        'https://images.ctfassets.net/qk4l4jfatr3e/1fCA0DFPa2x6ILlcfUugEo/5536f617695b99ad9087fd46cf9e696d/Frame_256__4_.png',
    'Inflammation Response':
        'https://images.ctfassets.net/qk4l4jfatr3e/6NhNbpJ7PQeWjuN9IHLiw8/85b32e59f91076fb2fffc8d3168d2c14/Frame_256__3_.png',
    'Immune System Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/3lktr5o4zns8e6bwEAOjLE/eb42fc8976785dc44b03975052e5679d/Frame_257__1_.png',
    'Oral Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/1CzeAek15sRTxLMINuL1l2/fa84e65622fd932b5afab157cb2628c7/Frame_256__2_.png',
    'Brain & Cognitive Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/30NNCihJPVvN1ltHaUe1Dx/9ef58156c5436349adee4a756a4d4b77/Frame_256__1_.png',
    'Heart & Cardiometabolic Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/25JqDHlKxtoqqVJdjIE2Xc/23634d1ae4491b22a01e49e819641ecc/Frame_256.png',
    'Biological Age':
        'https://images.ctfassets.net/qk4l4jfatr3e/78p4LzaeZkvMqcbWgQj9hq/ab1c6f8923277ae1dbfb3f47dfe55b23/Frame_257.png',
};

export const NEXT_PUBLIC_API_DOMAIN =
    ENVIRONMENT === 'production'
        ? 'https://my.viome.com/app'
        : 'https://test.viome.com/app';

// Used for when the URL for the product page differs from the slug
export const PRODUCT_URLS = {
    FCS: '/products/precision-supplements',
    CD: 'https://cancerdetect.viome.com',
};

export const CD_BLOG_CATEGORIES = ['discovery'];

export const VLS_BLOG_CATEGORIES = [
    'research-nutrition',
    'research-oral-health',
    'research-microbiome',
];

export const EXCLUDED_BLOG_CATEGORIES = [
    ...VLS_BLOG_CATEGORIES,
    ...CD_BLOG_CATEGORIES,
];

export const { SLACK_NOTIFICATION_URL, SLACK_HEADER_PRIVATE_KEY } = process.env;
