import '../styles/globals.css';
import Head from 'next/head';
import 'normalize.css';
import 'lib/styles/_base.scss';
import { ThemeProvider } from 'next-themes';
import { ViewportProvider } from 'utils/context/ViewportContextProvider';
import { getCookies, setCookie } from 'cookies-next';
import { cBoolean, getDeviceType } from '@viome/vnxt-nucleus';
import React, { useEffect, useState } from 'react';
import {
    COOKIE_DOMAIN,
    COOKIE_POLICY,
    COUNTRY_COOKIE_NAME,
    COUNTRY_IS_EU_OR_GB_COOKIE_NAME,
    GOOGLE_TAG_MANAGER_CONTAINER,
} from 'utils/constants';
import getUserCountry from 'utils/user/getUserCountry';
import getUserData from 'utils/user/getUserData';
import Script from 'next/script';
import { fontsClassNames } from 'lib/fonts/mapping';

function App(props) {
    const {
            Component,
            pageProps,
            clientCountry,
            clientCountryIsEUorGB,
            clientIp,
            clientDevice,
        } = props,
        clientCookies = getCookies(),
        acceptedCookiePolicy = clientCookies[COOKIE_POLICY.NAME] === 'true',
        clientCountryCookie = clientCookies[COUNTRY_COOKIE_NAME],
        clientCountryIsEUorGBCookie =
            clientCookies[COUNTRY_IS_EU_OR_GB_COOKIE_NAME],
        [user, setUserData] = useState(false),
        [userCountry, setUserCountry] = useState(
            // Check both server and client side cookie if country data exist
            clientCountry || clientCountryCookie,
        ),
        userCountryIsEUorGBProps =
            cBoolean(clientCountryIsEUorGB) ||
            cBoolean(clientCountryIsEUorGBCookie),
        [userCountryIsEUorGB, setUserCountryIsEUorGB] = useState(
            userCountryIsEUorGBProps,
        );

    useEffect(() => {
        const handleUserData = async () => {
                const userData = await getUserData();
                if (userData.error) return null;

                setUserData(userData);
                if (userData) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        user: userData,
                        event: 'logged_user',
                    });
                }
                return true;
            },
            handleUserCountry = async () => {
                let country = userCountry,
                    countryIsEUorGB = userCountryIsEUorGB;

                if (
                    !country ||
                    country === '-' ||
                    !clientCountryIsEUorGBCookie
                ) {
                    const { countryPhoneCode } = user,
                        countryResponse = await getUserCountry(clientIp);
                    if (countryPhoneCode) {
                        country = countryPhoneCode;
                    }
                    if (!countryResponse?.error) {
                        country = countryResponse?.country_code;
                        countryIsEUorGB =
                            countryResponse?.country_is_eu ||
                            countryResponse?.country_code === 'GB';
                    }
                    if (country) {
                        setUserCountry(country);
                    }
                    setUserCountryIsEUorGB(countryIsEUorGB);
                }

                // Sets a cookie if cookie doesn't already exist and country is vaild
                if (!clientCountryCookie && country) {
                    setCookie(COUNTRY_COOKIE_NAME, `${country}`, {
                        domain: COOKIE_DOMAIN,
                        maxAge: 365 * 86400, // One Year
                        path: '/',
                    });
                }

                // Sets a cookie for country is EU if cookie doesn't already exist and country is EU is vaild
                if (
                    !clientCountryIsEUorGBCookie &&
                    (typeof clientCookies[COOKIE_POLICY.NAME] === 'undefined' ||
                        acceptedCookiePolicy)
                ) {
                    setCookie(
                        COUNTRY_IS_EU_OR_GB_COOKIE_NAME,
                        cBoolean(clientCountryIsEUorGB) ||
                            cBoolean(clientCountryIsEUorGBCookie) ||
                            cBoolean(countryIsEUorGB),
                        {
                            domain: COOKIE_DOMAIN,
                            maxAge: 365 * 86400, // One Year
                            path: '/',
                        },
                    );
                }

                return country;
            };

        handleUserData();
        handleUserCountry();
    }, []);

    return (
        <ThemeProvider>
            <ViewportProvider clientDevice={clientDevice}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
                    />
                </Head>
                <div className={fontsClassNames}>
                    <Component {...pageProps} />
                </div>

                {/* Google Tag Manager */}
                <Script
                    id="google-tag-manager"
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}

                        gtag('consent', 'default', {
                        'ad_storage': '${
                            acceptedCookiePolicy ? 'granted' : 'denied'
                        }',
                        'analytics_storage': '${
                            acceptedCookiePolicy ? 'granted' : 'denied'
                        }',
                        'functionality_storage': 'granted',
                        'personalization_storage': 'granted',
                        'security_storage': 'granted',
                        });

                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_CONTAINER}');
                    `,
                    }}
                />
                {/* End Google Tag Manager */}
            </ViewportProvider>
        </ThemeProvider>
    );
}

App.getInitialProps = async ({ ctx }) => {
    // Get the client IP address
    const { req, res } = ctx,
        cookies = getCookies({ req, res }),
        countryCookie = cookies[COUNTRY_COOKIE_NAME],
        countryIsEUorGBCookie = cookies[COUNTRY_IS_EU_OR_GB_COOKIE_NAME],
        extraProps = {};

    if (req && req.headers['x-forwarded-for']) {
        const forwarded = req.headers['x-forwarded-for'],
            ip = forwarded
                ? forwarded.split(',')[0]
                : req.connection?.remoteAddress,
            clientIp = ip !== '::1' ? ip : '1.1.1.1';

        extraProps.clientIp = clientIp;
        extraProps.clientDevice = getDeviceType(req.headers['user-agent']);
    }

    extraProps.clientCountry = countryCookie;
    extraProps.clientCountryIsEUorGB = countryIsEUorGBCookie || false;
    return extraProps;
};

export default App;
